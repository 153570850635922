<template>
    <section>
        <modal-lateral ref="modalContinuarRegistro" titulo="Completar registro">
            <ValidationObserver ref="validacion">
                <div class="custom-scroll overflow-auto" style="height:calc(100vh - 130px);">
                    <div class="row mx-0 justify-center align-items-center">
                        <div class="border-separador br-50 p-1">
                            <i class="icon-leechero text-general f-20" />
                        </div>
                        <div class="col-auto text-general f-17 f-600">
                            Tu perfil
                        </div>
                    </div>
                    <div class="row mx-0 justify-center my-3">
                        <div class="col-auto">
                            <slim-cropper
                            ref="foto_cedis"
                            :options="slimOptions"
                            class="border cr-pointer leechero-picture"
                            />
                            <p class="text-center text-general f-12 py-1">
                                <b> Foto </b> <i>(Opcional)</i>
                            </p>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center">
                        <div class="col-10 my-1">
                            Nombre
                            <ValidationProvider v-slot="{errors}" rules="required|max:20" name="nombre">
                                <el-input v-model="model.prop_nombre" placeholder="Ej. Ana Maria" maxlength="20" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-1">
                            Apellido
                            <ValidationProvider v-slot="{errors}" rules="required|max:20" name="apellido">
                                <el-input v-model="model.prop_apellido" placeholder="Ej. Ana Maria" maxlength="20" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center align-items-center my-2">
                        <div class="border-separador br-50 p-1">
                            <i class="icon-pedidos text-general f-20" />
                        </div>
                        <div class="col-auto text-general f-17 f-600">
                            Facturación
                        </div>
                    </div>
                    <div class="row mx-0 justify-center">
                        <div class="col-10">
                            <p class="text-general f-15 f-400">
                                ¿Desea que generemos factura a su nombre o el de su tienda? Llene a continuación su información legal (Datos opcionales)
                            </p>
                        </div>
                        <div class="col-5 mt-3 d-middle">
                            <input v-model="model.tipo" :value="1" type="radio" class="option-input black radio" />
                            Persona Natural
                        </div>
                        <div class="col-5 mt-3 d-middle">
                            <input v-model="model.tipo" :value="2" type="radio" class="option-input black radio" />
                            Tienda
                        </div>
                        <template v-if="model.tipo == 1">
                            <div class="col-10 my-3">
                                <ValidationProvider v-slot="{errors}" rules="numeric|max:20" name="NIT">
                                    <p class="ml-2">Número de documento de identidad.*</p>
                                    <el-input v-model="model.nit" maxlength="20" class="w-100" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                                <!-- <el-input v-model="noIdentidad" class="w-100" placeholder="Ej: 123456789" /> -->
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-5 my-3">
                                <ValidationProvider v-slot="{errors}" rules="max:60" name="nombre tienda">
                                    <p class="ml-2">Nombre de la tienda</p>
                                    <el-input v-model="model.nombre" maxlength="60" class="w-100" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                                <!-- <el-input v-model="noIdentidad" class="w-100" placeholder="Ej: 123456789" /> -->
                            </div>
                            <div class="col-5 my-3">
                                <ValidationProvider v-slot="{errors}" rules="numeric|max:20" name="NIT">
                                    <p class="ml-2">Nit de la tienda*</p>
                                    <el-input v-model="model.nit" maxlength="20" class="w-100" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                                <!-- <el-input v-model="noIdentidad" class="w-100" placeholder="Ej: 123456789" /> -->
                            </div>
                        </template>
                    </div>
                    <div class="row mx-0 justify-center">
                        <div class="col-10">
                            <p class="text-general f-15 text-center">
                                Archivo del documento de identidad
                            </p>
                            <el-upload
                            ref="adjuntos"
                            class="upload-demo w-100"
                            action="#"
                            :multiple="false"
                            :limit="1"
                            :on-change="agregar_archivos"
                            :auto-upload="false"
                            :on-remove="handleRemove"
                            :file-list="fileList"
                            >
                                <div class="upload-documents w-100 text-center py-3 bg-light-f5 br-5 cr-pointer pt-1">
                                    Subir PDF o Imagen
                                </div>
                            </el-upload>
                        </div>
                    </div>
                    <div class="row mx-0 my-3 mt-4 justify-center align-items-center">
                        <div class="border-separador br-50 p-1">
                            <i class="icon-leechero text-general f-20" />
                        </div>
                        <div class="col-auto text-general f-17 f-600">
                            Tu ubicación
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{errors}" rules="required" name="dirección">
                                <p class="ml-2">Dirección</p>
                                <el-select
                                v-model="model.direccion"
                                filterable
                                remote
                                class="w-100"
                                reserve-keyword
                                :remote-method="remoteMethod"
                                @change="getDetails()"
                                >
                                    <el-option
                                    v-for="item in options"
                                    :key="item.place_id"
                                    :label="item.description"
                                    :value="item.description"
                                    />
                                </el-select>
                                <!-- <el-input v-model="model.direccion" maxlength="120" placeholder="Ej: Calle 20 #12 - 34" @change="buscarDireccion" />
                                <p v-if="model.direccion" class="pl-3 text-general2 f-14">Bucaramanga, Santander</p> -->
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{errors}" rules="max:40" name="código postal">
                                <p class="pl-3 text-general f-14">Código postal (Opcional)</p>
                                <el-input v-model="model.codigo_postal" maxlength="40" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{errors}" vid="barrio" rules="max:30" name="barrio / colonia">
                                <p class="pl-3 text-general f-14">Barrio / Colonia (Opcional)</p>
                                <el-input v-model="model.barrio" maxlength="30" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{errors}" rules="max:40" name="complemento">
                                <p class="pl-3 text-general f-14">Piso, apartamento o local (Opcional)</p>
                                <el-input v-model="model.direccion_anexo" maxlength="40" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{errors}" rules="required|max:30" vid="guardar_como" name="guardar como">
                                <p class="pl-3 text-general f-14">Guardar como</p>
                                <el-input v-model="model.guardar_como" maxlength="30" class="w-100" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-2">
                            <mapa ref="mapaPeq" :coordenadas="coordenadas" :buscar="model.direccion" @actualizar="actualizarCoordenadas" />
                            <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa()">
                                Haga click aquí, para seleccionar la ubicación de su tienda
                            </p>
                        </div>
                    </div>
                    <div class="row mx-0 justify-center px-3 align-items-center my-3">
                        <div class="border-separador br-50 p-1">
                            <i class="icon-pencil-outline text-general f-20" />
                        </div>
                        <div class="col-auto text-general f-17 f-600">
                            Acerca de ti
                        </div>
                    </div>
                    <div class="row mx-0 justify-center">
                        <div class="col-10">
                            <ValidationProvider v-slot="{errors}" rules="required" name="fecha de nacimiento">
                                <p class="pl-3 text-general f-14">¿Cuándo naciste?</p>
                                <el-date-picker
                                v-model="model.propietario_nacimiento"
                                type="date"
                                format="dd - MM - yyyy"
                                value-format="yyyy-MM-dd"
                                placeholder="Selecciona una fecha"
                                class="w-100"
                                :picker-options="pickerOptions"
                                />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto mt-3 d-middle ml-3">
                            <input v-model="model.propietario_genero" :value="2" type="radio" class="option-input black radio" />
                            Mujer
                        </div>
                        <div class="col-auto mt-3 d-middle">
                            <input v-model="model.propietario_genero" :value="1" type="radio" class="option-input black radio" />
                            Hombre
                        </div>
                        <div class="col-auto mt-3 d-middle">
                            <input v-model="model.propietario_genero" :value="3" type="radio" class="option-input black radio" />
                            Otro
                        </div>
                        <div class="col-10 my-3">
                            <ValidationProvider v-slot="{ errors }" rules="required|max:300" name="descripción">
                                <p class="pl-3 text-general f-14">Cuéntale a leeche sobre ti</p>
                                <el-input
                                v-model="model.descripcion_mi"
                                type="textarea"
                                show-word-limit
                                :rows="6"
                                maxlength="300"
                                class="w-100"
                                placeholder="Dinos quién eres, a qué te dedicas, qué te gusta hacer, por qué quieres ser parte de la app y qué expectativas tienes; queremos conocerte."
                                />
                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-10 my-2">
                            <ValidationProvider v-slot="{ errors }" rules="required|max:300" name="descripción cliente">
                                <p class="pl-3 text-general f-14">Mi descripción (Esta información la podrá ver tus clientes)</p>
                                <el-input v-model="model.descripcion" type="textarea" show-word-limit :rows="6" maxlength="300" class="w-100" placeholder="Describete, cúentale a tus clientes quien eres. como los quieres entender" />
                                <span class="text-danger f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 justify-center mb-4 border-top">
                    <div class="col-auto my-3">
                        <div class="text-general bg-general br-10 text-white py-1 f-17 px-5 cr-pointer" @click="updateDatos">
                            Guardar
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </modal-lateral>
        <mapa-google-modal
        ref="modalMapaGoogle"
        busqueda
        postal
        :buscar="model.direccion"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        @cancelar="cancelarMapa()"
        />
        <modal-no-direccion ref="modalNoDireccion" @change="onChangeModal" @onClose="onClose()" />
    </section>
</template>

<script>
import DatosTienda from "~/services/configurar/tendero-vip/datos_tienda";
import localizacion from '~/services/localizacion';
// import Localizaciones from "~/services/proyectos/localizaciones";

export default {
    data(){
        return {
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen de perfil',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() > Date.now()
                },
            },
            model: {
                guardar_como:null,
                descripcion:null,
                descripcion_mi:null,
                direccion:null,
                barrio:null,
                direccion_anexo:null,
                estado:null,
                id:null,
                idm_ciudad:null,
                idm_estado:null,
                idm_pais:null,
                latitud:0,
                logo:null,
                logo_mini:null,
                longitud:0,
                nit:null,
                id_direccion: null,
                nit_soporte:null,
                nombre:null,
                codigo_postal:null,
                tipo:1,
                prop_nombre:null,
                prop_apellido:null,
                propietario_nombre:null,
                propietario_genero: null,
                propietario_nacimiento: null,
                tienda: false,
            },
            coordenadas: {
                lat: 0,
                lng: 0,
            },
            archivo: [],
            fileList: [],
            options: [],
            location: {
                city: '',
                state: '',
                country: '',
                postalCode: '',
                direction: '',
            },
            googleMap: null
        }
    },
    computed: {
        getDireccionCon(){
            // const { ciudad = '' } = this.ciudades.find(x => x.id === this.model.idm_ciudad) || {};
            // const { nombre: pais = '' } = this.paises.find(x => x.id === this.model.idm_pais) || {};
            console.log('log', this.model.direccion);
            return [this.model.direccion ].filter(el => el).join(',')
        },
    },
    methods: {
        async toggle(){
            this.$refs.modalContinuarRegistro.toggle();
            await this.cargarDatos()
            await this.remoteMethod(this.model.direccion)
            await this.getDetails()
        },
        async getLocationFromBrowser(){
            try {
                let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();
                console.log(googleMaps ,' asa ');
                this.location = await localizacion.getLocationFromBrowser(googleMaps);
            } catch (error){
                this.error_catch(error)
            }
        },
        async getDetails(){
            let { place_id = '' } = this.options.find(el => el.description == this.model.direccion) ?? {}

            if(!place_id) return

            if(place_id == 'no_direccion'){
                this.onClose()
                return
            }

            let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();
            let geocoder = new googleMaps.Geocoder();

            geocoder.geocode({
                'placeId': place_id
            },
            async(responses, status) => {
                if (status == 'OK'){
                    let lat = responses[0].geometry.location.lat();
                    let lng = responses[0].geometry.location.lng();
                    this.coordenadas = {lat, lng}
                    this.$refs.mapaPeq.setPosition()
                    this.model.latitud = lat
                    this.model.longitud = lng

                    const obj = await localizacion.getLocationFromLatLng(googleMaps, lat, lng);
                    this.model.codigo_postal = +obj.postalCode
                    this.model.ciudad = obj.city
                    this.model.departamento = obj.state
                    this.model.pais = obj.country
                }
            });
        },
        async cargarDatos(){
            try {
                const { data } = await DatosTienda.getDatosTienda(this.$usuario.tienda.id)
                this.model = {...data.data}

                this.coordenadas.lat = +this.model.latitud
                this.coordenadas.lng = +this.model.longitud
                this.$refs.mapaPeq.setPosition()
                this.model.idm_ciudad = data.data.idm_ciudad

                this.model.tienda = data.data.nombre !== null ? true : false
                this.model.id_direccion = data.data.id_direccion

                this.location.city = data.data.ciudad
                this.location.state = data.data.departamento
                this.location.country = data.data.pais

                // this.model.direccion = [this.model.direccion, this.location.city, this.location.state, this.location.country ].filter(el => el).join(',')

                if (this.model.logo !== null){
                    this.$refs.foto_cedis.set_image(`${this.model.logo_firmado}`)
                } else {
                    this.$refs.foto_cedis.instanciaCrop.remove()
                }
                this.fileList = []
                if (this.model.nit_soporte_firmado){
                    this.fileList.push({
                        name: 'Ajunto nit',
                        url: this.model.nit_soporte_firmado
                    })
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        handleRemove(file, fileList){
            this.archivo = []
            this.fileList = []
            this.$refs.adjuntos.clearFiles()
        },
        async agregar_archivos(file, fileList){
            let nombreMayor100Carecteres = file.name.length > 100
            let tipo = null
            let tamano = null
            if (file.raw.type === 'application/pdf'){
                tipo = 1
                tamano = 20
            } else if (file.raw.type.split('/').slice(0, 1).pop() === 'image'){
                tipo = 2
                tamano = 1000
            } else if (file.raw.type === 'video/mp4'){
                tipo = 3
                tamano = 20
            } else {
                tipo = 4
                tamano = 25
            }

            const limite = file.size / 1024 / 1024 <= tamano
            if (!limite){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple los requisitos', 'warning')
                return
            }

            if (tipo == 3 || tipo == 4){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple con la extensión requerida(pdf ó imagenes)', 'warning')
                return
            }

            if (nombreMayor100Carecteres){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('Error', `¡El nombre del archivo ${file.name} excede los 100 caracteres!`, 'error')
                return
            }

            this.archivo.push(file.raw)
        },
        async updateDatos(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.foto_cedis.instanciaCrop.dataBase64.output
                this.model.logo = archivo.image
                this.model.logo_mini = null
                this.model.tipoAdmin = 1
                this.model.propietario_nombre = `${this.model.prop_nombre} ${this.model.prop_apellido}`
                this.model.id_tienda = this.$usuario.tienda.id

                if (this.archivo.length){
                    this.model.nit_soporte = this.archivo[0]
                }else if (this.fileList.length){
                    this.model.nit_soporte = 'si hay'
                }else{
                    this.model.nit_soporte = null
                }

                let formData = this.crear_formData(this.model)

                const {data} = await DatosTienda.updateDatos(formData)
                this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                this.$emit('actualizar')
                if (this.tienda){
                    this.$refs.adjuntos.clearFiles()
                    this.archivo = []
                }
                this.$refs.modalContinuarRegistro.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        abrirModalMapa(){
            this.$refs.modalContinuarRegistro.toggle();
            this.$refs.modalMapaGoogle.toggle()
        },
        cancelarMapa(){
            this.$refs.modalContinuarRegistro.toggle();
        },
        async actualizarCoordenadasCiudad({ latitud, longitud }){
            this.$refs.modalContinuarRegistro.toggle();
            this.model.latitud = latitud
            this.model.longitud = longitud
            let googleMaps = this.googleMap ?? await this.$iniciarGoogleMaps();

            const obj = await localizacion.getLocationFromLatLng(googleMaps, latitud, longitud);
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
            this.model.codigo_postal = +obj.postalCode
            this.model.direccion = obj.direction
            this.model.ciudad = obj.city
            this.model.departamento = obj.state
            this.model.pais = obj.country
            this.$refs.mapaPeq.setPosition()
            this.options = []

        },
        actualizarCoordenadas({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
        },
        buscarDireccion(){
            this.$refs.mapaPeq.accionBuscar();
        },
        async remoteMethod(query){
            try {
                let googleMaps = await this.$iniciarGoogleMaps();
                this.options = await localizacion.getPlaces(googleMaps, query);
                this.options.push({place_id: 'no_direccion', description: 'No aparece mi dirección'})
            } catch (e){
                this.options = [];
                this.options.push({place_id: 'no_direccion', description: 'No aparece mi dirección'})
            }
        },
        async onChangeModal(direccion){
            this.onClose()
            this.model.direccion = direccion
            await this.remoteMethod(direccion)
            await this.getDetails()
        },
        onClose(){
            this.$refs.modalNoDireccion.toggle()
            this.$refs.modalContinuarRegistro.toggle();
        }
    }
}
</script>

<style lang="scss" scoped>
.border-separador{border: 2px solid var(--text-general) !important;}
.leechero-picture{
    height:150px;
    width:150px;
    background-color: #FAFAFA;
    border-radius:8px;
}
.upload-documents{
    border: 1px dashed var(--color-general2);
}
</style>
