var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('modal-lateral',{ref:"modalContinuarRegistro",attrs:{"titulo":"Completar registro"}},[_c('ValidationObserver',{ref:"validacion"},[_c('div',{staticClass:"custom-scroll overflow-auto",staticStyle:{"height":"calc(100vh - 130px)"}},[_c('div',{staticClass:"row mx-0 justify-center align-items-center"},[_c('div',{staticClass:"border-separador br-50 p-1"},[_c('i',{staticClass:"icon-leechero text-general f-20"})]),_c('div',{staticClass:"col-auto text-general f-17 f-600"},[_vm._v(" Tu perfil ")])]),_c('div',{staticClass:"row mx-0 justify-center my-3"},[_c('div',{staticClass:"col-auto"},[_c('slim-cropper',{ref:"foto_cedis",staticClass:"border cr-pointer leechero-picture",attrs:{"options":_vm.slimOptions}}),_c('p',{staticClass:"text-center text-general f-12 py-1"},[_c('b',[_vm._v(" Foto ")]),_vm._v(" "),_c('i',[_vm._v("(Opcional)")])])],1)]),_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-10 my-1"},[_vm._v(" Nombre "),_c('ValidationProvider',{attrs:{"rules":"required|max:20","name":"nombre"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"placeholder":"Ej. Ana Maria","maxlength":"20"},model:{value:(_vm.model.prop_nombre),callback:function ($$v) {_vm.$set(_vm.model, "prop_nombre", $$v)},expression:"model.prop_nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 my-1"},[_vm._v(" Apellido "),_c('ValidationProvider',{attrs:{"rules":"required|max:20","name":"apellido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{attrs:{"placeholder":"Ej. Ana Maria","maxlength":"20"},model:{value:(_vm.model.prop_apellido),callback:function ($$v) {_vm.$set(_vm.model, "prop_apellido", $$v)},expression:"model.prop_apellido"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row mx-0 justify-center align-items-center my-2"},[_c('div',{staticClass:"border-separador br-50 p-1"},[_c('i',{staticClass:"icon-pedidos text-general f-20"})]),_c('div',{staticClass:"col-auto text-general f-17 f-600"},[_vm._v(" Facturación ")])]),_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-10"},[_c('p',{staticClass:"text-general f-15 f-400"},[_vm._v(" ¿Desea que generemos factura a su nombre o el de su tienda? Llene a continuación su información legal (Datos opcionales) ")])]),_c('div',{staticClass:"col-5 mt-3 d-middle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.tipo),expression:"model.tipo"}],staticClass:"option-input black radio",attrs:{"type":"radio"},domProps:{"value":1,"checked":_vm._q(_vm.model.tipo,1)},on:{"change":function($event){return _vm.$set(_vm.model, "tipo", 1)}}}),_vm._v(" Persona Natural ")]),_c('div',{staticClass:"col-5 mt-3 d-middle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.tipo),expression:"model.tipo"}],staticClass:"option-input black radio",attrs:{"type":"radio"},domProps:{"value":2,"checked":_vm._q(_vm.model.tipo,2)},on:{"change":function($event){return _vm.$set(_vm.model, "tipo", 2)}}}),_vm._v(" Tienda ")]),(_vm.model.tipo == 1)?[_c('div',{staticClass:"col-10 my-3"},[_c('ValidationProvider',{attrs:{"rules":"numeric|max:20","name":"NIT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2"},[_vm._v("Número de documento de identidad.*")]),_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"20"},model:{value:(_vm.model.nit),callback:function ($$v) {_vm.$set(_vm.model, "nit", $$v)},expression:"model.nit"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,1902212945)})],1)]:[_c('div',{staticClass:"col-5 my-3"},[_c('ValidationProvider',{attrs:{"rules":"max:60","name":"nombre tienda"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2"},[_vm._v("Nombre de la tienda")]),_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"60"},model:{value:(_vm.model.nombre),callback:function ($$v) {_vm.$set(_vm.model, "nombre", $$v)},expression:"model.nombre"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-5 my-3"},[_c('ValidationProvider',{attrs:{"rules":"numeric|max:20","name":"NIT"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2"},[_vm._v("Nit de la tienda*")]),_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"20"},model:{value:(_vm.model.nit),callback:function ($$v) {_vm.$set(_vm.model, "nit", $$v)},expression:"model.nit"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]],2),_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-10"},[_c('p',{staticClass:"text-general f-15 text-center"},[_vm._v(" Archivo del documento de identidad ")]),_c('el-upload',{ref:"adjuntos",staticClass:"upload-demo w-100",attrs:{"action":"#","multiple":false,"limit":1,"on-change":_vm.agregar_archivos,"auto-upload":false,"on-remove":_vm.handleRemove,"file-list":_vm.fileList}},[_c('div',{staticClass:"upload-documents w-100 text-center py-3 bg-light-f5 br-5 cr-pointer pt-1"},[_vm._v(" Subir PDF o Imagen ")])])],1)]),_c('div',{staticClass:"row mx-0 my-3 mt-4 justify-center align-items-center"},[_c('div',{staticClass:"border-separador br-50 p-1"},[_c('i',{staticClass:"icon-leechero text-general f-20"})]),_c('div',{staticClass:"col-auto text-general f-17 f-600"},[_vm._v(" Tu ubicación ")])]),_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-10 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"dirección"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2"},[_vm._v("Dirección")]),_c('el-select',{staticClass:"w-100",attrs:{"filterable":"","remote":"","reserve-keyword":"","remote-method":_vm.remoteMethod},on:{"change":function($event){return _vm.getDetails()}},model:{value:(_vm.model.direccion),callback:function ($$v) {_vm.$set(_vm.model, "direccion", $$v)},expression:"model.direccion"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.place_id,attrs:{"label":item.description,"value":item.description}})}),1),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 my-2"},[_c('ValidationProvider',{attrs:{"rules":"max:40","name":"código postal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Código postal (Opcional)")]),_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"40"},model:{value:(_vm.model.codigo_postal),callback:function ($$v) {_vm.$set(_vm.model, "codigo_postal", $$v)},expression:"model.codigo_postal"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 my-2"},[_c('ValidationProvider',{attrs:{"vid":"barrio","rules":"max:30","name":"barrio / colonia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Barrio / Colonia (Opcional)")]),_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"30"},model:{value:(_vm.model.barrio),callback:function ($$v) {_vm.$set(_vm.model, "barrio", $$v)},expression:"model.barrio"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 my-2"},[_c('ValidationProvider',{attrs:{"rules":"max:40","name":"complemento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Piso, apartamento o local (Opcional)")]),_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"40"},model:{value:(_vm.model.direccion_anexo),callback:function ($$v) {_vm.$set(_vm.model, "direccion_anexo", $$v)},expression:"model.direccion_anexo"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:30","vid":"guardar_como","name":"guardar como"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Guardar como")]),_c('el-input',{staticClass:"w-100",attrs:{"maxlength":"30"},model:{value:(_vm.model.guardar_como),callback:function ($$v) {_vm.$set(_vm.model, "guardar_como", $$v)},expression:"model.guardar_como"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 my-2"},[_c('mapa',{ref:"mapaPeq",attrs:{"coordenadas":_vm.coordenadas,"buscar":_vm.model.direccion},on:{"actualizar":_vm.actualizarCoordenadas}}),_c('p',{staticClass:"f-13 mt-2 text-gris2 cr-pointer",on:{"click":function($event){return _vm.abrirModalMapa()}}},[_vm._v(" Haga click aquí, para seleccionar la ubicación de su tienda ")])],1)]),_c('div',{staticClass:"row mx-0 justify-center px-3 align-items-center my-3"},[_c('div',{staticClass:"border-separador br-50 p-1"},[_c('i',{staticClass:"icon-pencil-outline text-general f-20"})]),_c('div',{staticClass:"col-auto text-general f-17 f-600"},[_vm._v(" Acerca de ti ")])]),_c('div',{staticClass:"row mx-0 justify-center"},[_c('div',{staticClass:"col-10"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"fecha de nacimiento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("¿Cuándo naciste?")]),_c('el-date-picker',{staticClass:"w-100",attrs:{"type":"date","format":"dd - MM - yyyy","value-format":"yyyy-MM-dd","placeholder":"Selecciona una fecha","picker-options":_vm.pickerOptions},model:{value:(_vm.model.propietario_nacimiento),callback:function ($$v) {_vm.$set(_vm.model, "propietario_nacimiento", $$v)},expression:"model.propietario_nacimiento"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-auto mt-3 d-middle ml-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.propietario_genero),expression:"model.propietario_genero"}],staticClass:"option-input black radio",attrs:{"type":"radio"},domProps:{"value":2,"checked":_vm._q(_vm.model.propietario_genero,2)},on:{"change":function($event){return _vm.$set(_vm.model, "propietario_genero", 2)}}}),_vm._v(" Mujer ")]),_c('div',{staticClass:"col-auto mt-3 d-middle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.propietario_genero),expression:"model.propietario_genero"}],staticClass:"option-input black radio",attrs:{"type":"radio"},domProps:{"value":1,"checked":_vm._q(_vm.model.propietario_genero,1)},on:{"change":function($event){return _vm.$set(_vm.model, "propietario_genero", 1)}}}),_vm._v(" Hombre ")]),_c('div',{staticClass:"col-auto mt-3 d-middle"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.propietario_genero),expression:"model.propietario_genero"}],staticClass:"option-input black radio",attrs:{"type":"radio"},domProps:{"value":3,"checked":_vm._q(_vm.model.propietario_genero,3)},on:{"change":function($event){return _vm.$set(_vm.model, "propietario_genero", 3)}}}),_vm._v(" Otro ")]),_c('div',{staticClass:"col-10 my-3"},[_c('ValidationProvider',{attrs:{"rules":"required|max:300","name":"descripción"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Cuéntale a leeche sobre ti")]),_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","show-word-limit":"","rows":6,"maxlength":"300","placeholder":"Dinos quién eres, a qué te dedicas, qué te gusta hacer, por qué quieres ser parte de la app y qué expectativas tienes; queremos conocerte."},model:{value:(_vm.model.descripcion_mi),callback:function ($$v) {_vm.$set(_vm.model, "descripcion_mi", $$v)},expression:"model.descripcion_mi"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-10 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:300","name":"descripción cliente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-14"},[_vm._v("Mi descripción (Esta información la podrá ver tus clientes)")]),_c('el-input',{staticClass:"w-100",attrs:{"type":"textarea","show-word-limit":"","rows":6,"maxlength":"300","placeholder":"Describete, cúentale a tus clientes quien eres. como los quieres entender"},model:{value:(_vm.model.descripcion),callback:function ($$v) {_vm.$set(_vm.model, "descripcion", $$v)},expression:"model.descripcion"}}),_c('span',{staticClass:"text-danger f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])]),_c('div',{staticClass:"row mx-0 justify-center mb-4 border-top"},[_c('div',{staticClass:"col-auto my-3"},[_c('div',{staticClass:"text-general bg-general br-10 text-white py-1 f-17 px-5 cr-pointer",on:{"click":_vm.updateDatos}},[_vm._v(" Guardar ")])])])])],1),_c('mapa-google-modal',{ref:"modalMapaGoogle",attrs:{"busqueda":"","postal":"","buscar":_vm.model.direccion,"coordenadas-entrada":_vm.coordenadas},on:{"actualizar":_vm.actualizarCoordenadasCiudad,"cancelar":function($event){return _vm.cancelarMapa()}}}),_c('modal-no-direccion',{ref:"modalNoDireccion",on:{"change":_vm.onChangeModal,"onClose":function($event){return _vm.onClose()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }